import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  Container,
  Button,
  Nav,
  Form,
  FloatingLabel,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import ReactPlayer from 'react-player'

export default function App() {
  const navigate = useNavigate();

  const imageData = [
    "images/kurum-2.png",
    "images/kurum-1.png",
    "images/kurum-3.png",
    "images/kurum-6.png",
    "images/kurum-10.png",
    "images/kurum-14.png",
    "images/ogretmen-12.png",
    "images/veli-11.png",
    "images/kurum-25.png",
    "images/ogrenci-24.png",
    "images/ogretmen-25.png",
    "images/ogrenci-26.png",
  ];

  const { currentUser, realTimeUserData } = useAuth();

  useEffect(() => {
    if (currentUser) {
      if (realTimeUserData) {
        switch (realTimeUserData.role) {
          case "organization":
            navigate("/kurum-paneli");
            break;
          case "teacher":
            navigate("/ogretmen-paneli");
            break;
          case "parent":
            navigate("/veli-paneli");
            break;
          case "admin":
            navigate("/admin-paneli");
            break;
          case "student":
            navigate("/ogrenci-paneli");
            break;
          default:
            break;
        }
      }
    }
  }, [currentUser, realTimeUserData, navigate]);

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeIndex < imageData.length - 1) {
        setActiveIndex((prev) => prev + 1);
      } else {
        setActiveIndex(0);
      }
    }, 5000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [activeIndex, imageData.length]);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      ref.current.clientHeight && setHeight(ref.current.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [ref]);

  useEffect(() => {
    if (!ref2.current) return;
    const resizeObserver = new ResizeObserver(() => {
      ref2.current.clientWidth && setWidth(ref2.current.clientWidth);
    });
    resizeObserver.observe(ref2.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [ref2]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const [selectedImage, setSelectedImage] = useState()

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // iletisim formu stateleri
  const [name, setName] = useState("")
  const [mail, setMail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  // satış formu stateleri
  const [name2, setName2] = useState("")
  const [organizationName, setOrganizationName] = useState("")
  const [studentCount, setStudentCount] = useState("")
  const [teacherCount, setTeacherCount] = useState("")
  const [mail2, setMail2] = useState("")
  const [phone2, setPhone2] = useState("")
  const [adress, setAdress] = useState("")

  const sendMail = async (type) => {
    if (type === "sales") {
      if (name2 && organizationName && studentCount && teacherCount && mail2 && phone2 && adress) {
        const config = {
          headers: {
            "Content-Type": "application/json",
          }
        };
  
        const url = "https://www.kriptometrik.com/derece/yks-satis.php";
  
        const data = {
          name: name2,
          organizationName: organizationName,
          studentCount: studentCount,
          teacherCount: teacherCount,
          mail: mail2,
          phone: phone2,
          address: adress,
        }
  
        await axios.post(url, JSON.stringify(data), config)
  
        setName2("")
        setOrganizationName("")
        setStudentCount("")
        setTeacherCount("")
        setMail2("")
        setPhone2("")
        setAdress("")
        handleClose()
      } else {
        alert("Lütfen tüm alanları tam ve doğru doldurun.")
      }
    } else {
      if (name && mail && phone && message) {
        const config = {
          headers: {
            "Content-Type": "application/json",
          }
        };

        const url = "https://www.kriptometrik.com/derece/yks-iletisim.php";

        const data = {
          name: name,
          mail: mail,
          phone: phone,
          message: message,
        }

        await axios.post(url, JSON.stringify(data), config)

        setName("")
        setMail("")
        setPhone("")
        setMessage("")
        handleClose4()
      } else {
        alert("Lütfen tüm alanları tam ve doğru doldurun.")
      }
    }
  }

  return (
    <>
      {!currentUser && (
        <>
          <Navbar
            ref={ref2}
            expand="md"
            className="bg-body-tertiary border-bottom"
          >
            <Container fluid="lg">
              <Navbar.Brand href="/">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt=""
                    src="images/dereceyks-logo-transparent.png"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "3px",
                    }}
                  />
                  <p style={{ margin: "0", fontWeight: "500" }}>Derece: YKS</p>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  className="me-auto"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <NavDropdown title="Ürünler" id="collasible-nav-dropdown">
                    <NavDropdown.Item href='/'>Kurum Paneli</NavDropdown.Item>
                    <NavDropdown.Item href='/'>Öğretmen Paneli</NavDropdown.Item>
                    <NavDropdown.Item href='/'>Veli Paneli</NavDropdown.Item>
                    <NavDropdown.Item href='/'>Öğrenci Paneli</NavDropdown.Item>
                  </NavDropdown> */}
                  <Nav.Link
                    href="#kurum-paneli-line"
                    style={{ textAlign: "center" }}
                  >
                    Kurum
                  </Nav.Link>
                  <Nav.Link
                    href="#ogretmen-paneli-line"
                    style={{ textAlign: "center" }}
                  >
                    Öğretmen
                  </Nav.Link>
                  <Nav.Link
                    href="#ogrenci-paneli-line"
                    style={{ textAlign: "center" }}
                  >
                    Öğrenci
                  </Nav.Link>
                  <Nav.Link
                    href="#veli-paneli-line"
                    style={{ textAlign: "center" }}
                  >
                    Veli
                  </Nav.Link>
                  <Nav.Link
                    href="#iletisim-paneli-line"
                    style={{ textAlign: "center" }}
                  >
                    İletişim
                  </Nav.Link>
                </Nav>
                <Nav
                  className="me-auto"
                  style={{ justifyContent: "flex-end", minWidth: "120px" }}
                >
                  <Button
                    variant="outline-primary"
                    onClick={() => navigate("/giris")}
                  >
                    Giriş Yap
                  </Button>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container
            fluid
            style={{
              paddingBottom: "24px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Container
              className="home-container"
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                className="text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                }}
              >
                <h2
                  style={{ textAlign: "center", fontWeight: "700" }}
                  className="hero-title text-body-emphasis"
                >
                  Lise Eğitimi ve Üniversite Sınavına Hazırlık İçin Hazırlanmış
                  Kapsamlı Bir Sistem
                </h2>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "24px",
                  }}
                >
                  <p
                    style={{ fontWeight: "300", margin: 0, padding: 0 }}
                    className="hero-subtitle mb-4"
                  >
                    Kurum, öğretmen, öğrenci ve velileri aynı sistemde
                    buluşturan 'Derece' öğrencilere lise eğitimi ve üniversite
                    sınavına hazırlık sürecinde fayda sağlayacak birçok özellik
                    sunarken; kurumların, öğretmenlerin ve velilerin öğrencilerinin
                    çalışmalarını takip edebilmelerine ve dahasına olanak
                    sağlar.
                  </p>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    variant="outline-primary"
                    onClick={handleShow3}
                  >
                    {" "}
                    <i
                      className="fa fa-youtube-play"
                      style={{ fontSize: "32px", marginRight: "8px" }}
                    ></i>{" "}
                    Tanıtım Videosu
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="container"
                    style={{
                      margin: "0 8px",
                      position: "relative",
                      maxWidth: "100%",
                      height: height,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    {imageData.map((item, index) => {
                      return (
                        <img
                          ref={ref}
                          key={index}
                          onClick={() => {
                            setSelectedImage(item)
                            handleShow2()
                          }}
                          style={{
                            width: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            objectFit: "contain",
                            opacity: index === activeIndex ? "1" : "0",
                            transition: "opacity 2s",
                            zIndex: 100 + index,
                            pointerEvents: index === activeIndex ? "auto" : "none"
                          }}
                          alt=""
                          src={item}
                          className="hero-image"
                          loading="eager"
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="container px-4" style={{ marginBottom: "48px", marginTop: "12px" }}>
                <div
                  style={{ marginBottom: "36px" }}
                  className="border-bottom"
                />

                <div
                  style={{ padding: "0 36px" }}
                  className="row row-cols-1 row-cols-md-1 row-cols-lg-2 align-items-md-center g-5 container-with-padding"
                >
                  <div className="col d-flex flex-column  gap-2 deneme">
                    <h2
                      style={{
                        maxWidth: "520px",
                        marginBottom: "12px",
                        marginTop: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title-7"
                    >
                      Başarıya Giden Yolda <br /> Rol Alan Herkes İçin
                    </h2>
                    <p
                      style={{ fontWeight: "300", maxWidth: "520px" }}
                      className="feature-description-2"
                    >
                      Sistemimizde kurum, öğretmen, öğrenci ve veli için olmak
                      üzere dört farklı panel bulunmaktadır. Benzer amaçlara
                      hizmet eden ekranlara ek olarak her panelde o role özgü
                      ekranlar ve özellikler bulunmaktadır. Bunları keşfetmek
                      için aşağıdaki detayları inceleyebilirsiniz.
                    </p>
                  </div>

                  <div className="col">
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-md-2 g-4">
                      <div
                        style={{ marginTop: "24px32px" }}
                        className="col d-flex flex-column gap-2 role-container"
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            background: "#F8F9FA",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt=""
                            src="images/school-icon.png"
                            style={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        </div>

                        <h4 className="fw-semibold mb-0 text-body-emphasis">
                          Kurum Paneli
                        </h4>
                        <p
                          style={{ marginBottom: 0, fontWeight: "300" }}
                          className="feature-description"
                        >
                          Öğretmen, öğrenci ve velilerinizi ekleyip takibe
                          başlayın.
                        </p>
                      </div>

                      <div
                        style={{ marginTop: "24px32px" }}
                        className="col d-flex flex-column gap-2 role-container"
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            background: "#F8F9FA",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt=""
                            src="images/teacher-icon.png"
                            style={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        </div>

                        <h4 className="fw-semibold mb-0 text-body-emphasis">
                          Öğretmen Paneli
                        </h4>
                        <p
                          style={{ marginBottom: 0, fontWeight: "300" }}
                          className="feature-description"
                        >
                          Öğrencilerinizin çalışmalarını ve gelişimini takip
                          edin.
                        </p>
                      </div>

                      <div
                        style={{ marginTop: "24px32px" }}
                        className="col d-flex flex-column gap-2 role-container"
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            background: "#F8F9FA",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt=""
                            src="images/boy.png"
                            style={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        </div>
                        <h4 className="fw-semibold mb-0 text-body-emphasis">
                          Öğrenci Paneli
                        </h4>
                        <p
                          style={{ marginBottom: 0, fontWeight: "300" }}
                          className="feature-description"
                        >
                          Çalışmalarınızı kaydedin. Analizlere göz atın ve
                          gelişiminizi takip edin.
                        </p>
                      </div>

                      <div
                        style={{ marginTop: "24px32px" }}
                        className="col d-flex flex-column gap-2 role-container"
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            background: "#F8F9FA",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt=""
                            src="images/parent-icon.png"
                            style={{
                              width: "32px",
                              height: "32px",
                            }}
                          />
                        </div>
                        <h4 className="fw-semibold mb-0 text-body-emphasis">
                          Veli Paneli
                        </h4>
                        <p
                          style={{ marginBottom: 0, fontWeight: "300" }}
                          className="feature-description"
                        >
                          Çocuğunuzun çalışmalarını takip edin. Durumundan
                          haberdar olun.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="kurum-paneli-line"
                  style={{ marginTop: "36px" }}
                  className="border-bottom"
                />
              </div>

              <div
                className="container container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "24px 0 48px 0",
                  }}
                  className=" text-center bg-body-tertiary rounded-3 role-panel-section"
                >
                  <div
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#F8F9FA",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="images/school-icon.png"
                      style={{
                        width: "42px",
                        height: "42px",
                      }}
                    />
                  </div>
                  <h2
                    style={{
                      width: "65%",
                      marginBottom: "12px",
                      textAlign: "center",
                      marginRight: 0,
                      padding: 0,
                    }}
                    className="fw-bold text-body-emphasis feature-title"
                  >
                    Kurum Paneli
                  </h2>
                  <p
                    style={{ fontWeight: "300", margin: 0, padding: 0 }}
                    className="hero-subtitle"
                  >
                    Öğretmen, öğrenci ve velilerinizi sisteme ekleyin. Oluşan
                    hesap bilgilerini onlarla paylaşın. Öğrencilerinizin
                    çalışmalarını takip etmeye ve senkronize bir şekilde
                    analizleri görüntülemeye başlayın. Öğretmen ve velilerinizle
                    yapacağınız görüşmelerde bu verilerden
                    yararlanarak öğrenci için en uygun strateji ve çalışma
                    dinamiklerini belirleyin.
                  </p>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/kurum-3.png"
                      onClick={() => {
                        setSelectedImage("images/kurum-3.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-primary-subtle border border-primary-subtle text-primary-emphasis rounded-pill"
                    >
                      Kurumlar İçin
                    </span>

                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Öğretmen, Öğrenci ve <br />
                      Veli Hesaplarını Yönetin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Kurumunuz bünyesindeki öğrenci ve öğretmenleri platforma
                      ekleyin. Öğrenci hesabı oluştuğunda velisi için de bir
                      hesap oluşacaktır. Öğretmen hesaplarını
                      oluştururken veya daha sonrasında öğrenci ataması
                      yapabilirsiniz. Bu sayede öğretmenler sadece
                      ilgilendikleri öğrencileri takip ederler. Hesapları
                      oluşturduktan sonra listelediğimiz giriş bilgilerini
                      kişilerle paylaştığınızda platforma erişebilirler.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{ margin: 0 }}
                  className="row flex-lg-row-reverse align-items-center"
                >
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/kurum-14.png"
                      onClick={() => {
                        setSelectedImage("images/kurum-14.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-primary-subtle border border-primary-subtle text-primary-emphasis rounded-pill"
                    >
                      Kurumlar İçin
                    </span>

                    <h2
                      style={{ maxWidth: "500px", marginBottom: "12px" }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Öğrencilerinizin Çalışmalarını Takip Edin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        margin: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Öğrencilerinizin kurum içi ve kurum dışı tüm çalışmalarını
                      takip edin. Kurum panelinde bulunan konu takibi, ders
                      çalışma süresi, çözülen soru sayısı ve deneme sınavları
                      gibi ekranlarla öğrencinizin çalışmalarını takip ederken
                      öğrenci analizleri ekranında seçtiğiniz öğrencinin çalışma
                      aktivitelerine dair grafikler görüntüleyin.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/kurum-2.png"
                      onClick={() => {
                        setSelectedImage("images/kurum-2.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-primary-subtle border border-primary-subtle text-primary-emphasis rounded-pill"
                    >
                      Kurumlar İçin
                    </span>

                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Doğru Stratejilerle Başarıya Daha Yakın Öğrenciler
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Kurum panelinde sizlere sunmuş olduğumuz tüm çalışma
                      verileri ve bunların sonucunda oluşan analizlerle öğretmen
                      ve veli toplantılarına yeni bir dinamik sağlıyoruz.
                      Öğrencilerin potansiyellerini
                      tespit etmek ve doğru stratejiler geliştirmek onları
                      başarıya yaklaştıracaktır.
                    </p>
                  </div>
                </div>
              </div>

              <div className="container">
                <div
                  id="ogretmen-paneli-line"
                  style={{ margin: "48px 0" }}
                  className="border-bottom"
                />
              </div>

              <div
                className="container container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "24px 0 48px 0",
                  }}
                  className="text-center bg-body-tertiary rounded-3 role-panel-section"
                >
                  <div
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#F8F9FA",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="images/teacher-icon.png"
                      style={{
                        width: "42px",
                        height: "42px",
                      }}
                    />
                  </div>
                  <h2
                    style={{
                      width: "65%",
                      marginBottom: "12px",
                      textAlign: "center",
                      marginRight: 0,
                      padding: 0,
                    }}
                    className="fw-bold text-body-emphasis feature-title"
                  >
                    Öğretmen Paneli
                  </h2>
                  <p
                    style={{ fontWeight: "300", margin: 0, padding: 0 }}
                    className="hero-subtitle"
                  >
                    Kurumunuzun size atadığı öğrencileri takip etmeye başlayın.
                    Öğrencilerinizin hangi dersi ne kadar çalıştığını, ağırlıklı
                    olarak nelere çalıştığını ve neleri eksik bıraktığını,
                    konularından hangilerini ve kaçını tamamladığını, hangi
                    dersin hangi konusundan kaç soru çözdüğünü ve girmiş olduğu
                    tüm denemelerdeki istatistiklerini dilediğiniz an
                    görüntüleyin. Öğrencilerinizin çözemediği soruları
                    dilediğiniz vakit çözün ve fotoğrafını çekip sisteme
                    yükleyin.
                  </p>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogretmen-19.png"
                      onClick={() => {
                        setSelectedImage("images/ogretmen-19.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-danger-subtle border border-danger-subtle text-danger-emphasis rounded-pill"
                    >
                      Öğretmenler İçin
                    </span>
                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Öğrencilerinizin Çalışmalarını Takip Edin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Öğrencilerinizin tüm çalışmalarını takip edin. Öğretmen
                      panelinde bulunan konu takibi, ders çalışma süresi,
                      çözülen soru sayısı ve deneme sınavları gibi ekranlarla
                      öğrencinizin çalışmalarını takip ederken öğrenci
                      analizleri ekranında seçtiğiniz öğrencinin çalışmalarına
                      dair analizler ve grafikler görüntüleyin.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{ margin: 0 }}
                  className="row flex-lg-row-reverse align-items-center"
                >
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogretmen-24.png"
                      onClick={() => {
                        setSelectedImage("images/ogretmen-24.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-danger-subtle border border-danger-subtle text-danger-emphasis rounded-pill"
                    >
                      Öğretmenler İçin
                    </span>

                    <h2
                      style={{ maxWidth: "500px", marginBottom: "12px" }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Öğrencilerinizin Çözemediği Soruları Çözün
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        margin: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Çözülemeyen sorular ekranında kurum öğrencilerinin sizin
                      branşınızdan çözemediği soruları görüntüleyebilir,
                      dilerseniz çözüp fotoğrafını çekip sisteme
                      yükleyebilirsiniz. Bu sayede vakit ayırmak istediğinizde
                      etüt planlamanıza ve öğrencinin kuruma gelmesine gerek
                      kalmadan onlarla ilgilenebilir ve sorularını
                      çözebilirsiniz.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogretmen-2.png"
                      onClick={() => {
                        setSelectedImage("images/ogretmen-2.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-danger-subtle border border-danger-subtle text-danger-emphasis rounded-pill"
                    >
                      Öğretmenler İçin
                    </span>

                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Öğrencilerinizi Analiz Edin ve Çalışmalar Planlayın
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Öğretmen panelinde sizlere sunmuş olduğumuz tüm çalışma
                      verileri ve bunların sonucunda oluşan analizleri
                      öğrencilerin durumlarını tespit etmek için kullanın.
                      Böylece onlara yeni çalışma planları yapabilir, etüt
                      ihtiyacı olduğunu düşündüğünüzde etüt planlayabilirsiniz.
                    </p>
                  </div>
                </div>
              </div>

              <div className="container">
                <div
                  id="ogrenci-paneli-line"
                  style={{ margin: "48px 0" }}
                  className="border-bottom"
                />
              </div>

              <div
                className="container container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "24px 0 48px 0",
                  }}
                  className="text-center bg-body-tertiary rounded-3 role-panel-section"
                >
                  <div
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#F8F9FA",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="images/boy.png"
                      style={{
                        width: "42px",
                        height: "42px",
                      }}
                    />
                  </div>
                  <h2
                    style={{
                      width: "65%",
                      marginBottom: "12px",
                      textAlign: "center",
                      marginRight: 0,
                      padding: 0,
                    }}
                    className="fw-bold text-body-emphasis feature-title"
                  >
                    Öğrenci Paneli
                  </h2>
                  <p
                    style={{ fontWeight: "300", margin: 0, padding: 0 }}
                    className="hero-subtitle"
                  >
                    Tüm çalışmalarınızı tek bir yerden takip edin. Süreci en
                    etkili olacak şekilde yönetin. Konu takibi, ders çalışma
                    süresi ve çözülen soru sayısı ekranlarında çalışmalarınızı
                    takip edin. Denemeler ve çözülemeyen sorular ekranlarında
                    denemelerinizi ekleyip istatistikleri görüntülerken
                    çözemediğiniz soruları klasörleyip, öğretmeninize sormak
                    için çözülemeyen sorular ekranını kullanının. 'Kim Ne
                    Yapıyor?' ekranında kurumdaki arkadaşlarınızın anonim bir
                    şekilde aktivitelerine göz atın ve motivasyon kazanın.
                    Analiz ekranını sık sık ziyaret edip gelişiminizi takip
                    edin.
                  </p>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogrenci-5.png"
                      onClick={() => {
                        setSelectedImage("images/ogrenci-5.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill"
                    >
                      Öğrenciler İçin
                    </span>

                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Tamamladığınız Ders ve Konuları İşaretleyin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Konu takibi ekranında 9, 10, 11, ve 12. sınıf derslerini
                      ve derslerin altında konularını görüntüleyip tamamlamış
                      olduğunuz konulara işaret koyabilirsiniz. Bununla birlikte
                      ilgili dersin, sınıfın ve gireceğiniz sınavların
                      müfredatlarının yüzde kaçını tamamlamış olduğunuzu
                      görebilirsiniz. Konuları tamamladıkça işaretlemeniz konu
                      çalışma sürecini yönetmenize ve eksiklerinizi tespit
                      etmenize yardımcı olurken analiz ekranındaki grafiklerin
                      de şekillenmesini sağlar.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{ margin: 0 }}
                  className="row flex-lg-row-reverse align-items-center"
                >
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogrenci-9.png"
                      onClick={() => {
                        setSelectedImage("images/ogrenci-9.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill"
                    >
                      Öğrenciler İçin
                    </span>

                    <h2
                      style={{ maxWidth: "500px", marginBottom: "12px" }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Çalışırken Süre Tutun ve Vaktinizi İyi Değerlendirin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        margin: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Ders çalışma aktivitelerinizden sonra ders çalışma süresi
                      ekranında hangi dersin hangi konusunu kaç dakika
                      çalıştığınızı ekleyin. Bu sayede süreç boyunca hangi
                      derslere ve hangi konulara ne kadar süre çalıştığınızı
                      bilebilir, az çalıştığınız ders ve konuları tespit
                      edebilirsiniz. Çalışma sürelerinizi eklemeniz analiz
                      ekranında günlük, haftalık ve aylık olarak ne kadar
                      çalıştığınızı gösteren grafikleri şekillendirir.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogrenci-13.png"
                      onClick={() => {
                        setSelectedImage("images/ogrenci-13.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill"
                    >
                      Öğrenciler İçin
                    </span>

                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Çözdüğünüz Soru Sayısını Ekleyin ve Takip Edin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Soru çözdükten sonra çözülen soru sayısı ekranında hangi
                      dersin hangi konusundan kaç soru çözdüğünüzü girin.
                      Bununla birlikte derslerden ve konulardan kaç soru
                      çözdüğünüzü bilebilir, az soru çözdüğünüz ders ve konuları
                      tespit edebilirsiniz. Çözdüğünüz soru sayılarını eklemeniz
                      analiz ekranında günlük, haftalık ve aylık olarak
                      çözdüğünüz toplam soru sayılarını gösteren grafikleri
                      şekillendirir.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{ margin: 0 }}
                  className="row flex-lg-row-reverse align-items-center"
                >
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogrenci-17.png"
                      onClick={() => {
                        setSelectedImage("images/ogrenci-17.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill"
                    >
                      Öğrenciler İçin
                    </span>

                    <h2
                      style={{ maxWidth: "500px", marginBottom: "12px" }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Tüm Denemeleriniz <br /> Tek Bir Yerde
                    </h2>

                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        margin: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Kurum içi ve dışında yaptığınız tüm denemeleri denemeler
                      ekranında ekleyin. Sınıf, branş, TYT, AYT ve YDT gibi
                      birçok farklı deneme türü ekleyebilir kendi türünde
                      klasörleyebilirsiniz. Doğru, yanlış ve boş sayılarınızı
                      girdiğinizde sistem netinizi ve diğer istatistikleri
                      hesaplayarak kayıt eder. Dilediğiniz vakit bu bilgileri
                      görüntüleyebilir ve analizler ekranında denemelerde yapmış
                      olduğunuz netlerle ilgili grafikleri görebilirsiniz.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogrenci-24.png"
                      onClick={() => {
                        setSelectedImage("images/ogrenci-24.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill"
                    >
                      Öğrenciler İçin
                    </span>

                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Çözemediğiniz Soruları Öğretmenlerinize Sorun
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Denemelerde ve soru çözerken yapamadığınız soruları
                      çözülemeyen sorular ekranında derslere göre klasörleyip
                      süreç boyunca yanınızda taşıyabilirsiniz. Ayrıca
                      kurumunuzun ilgili branştaki hocaları sorularınızı
                      görüntüleyebilir, çözüp fotoğrafını sisteme
                      yükleyebilirler.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ padding: "0 36px" }}
              >
                <div
                  style={{ margin: 0 }}
                  className="row flex-lg-row-reverse align-items-center"
                >
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/ogrenci-26.png"
                      onClick={() => {
                        setSelectedImage("images/ogrenci-26.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill"
                    >
                      Öğrenciler İçin
                    </span>

                    <h2
                      style={{ maxWidth: "500px", marginBottom: "12px" }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Arkadaşlarınız Ne Çalışıyor?
                    </h2>

                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        margin: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Tamamen anonim bir şekilde kurumdaki diğer öğrencilerin
                      bazı aktivitelerini izleyebileceğiniz kim ne çalışıyor
                      ekranında arkadaşlarınızın çalışmalarını görebilir ve
                      motive olabilirsiniz. Çalışmaya devam edin ve kimseden
                      geri kalmayın.
                    </p>
                  </div>
                </div>
              </div>

              <div className="container">
                <div
                  id="veli-paneli-line"
                  style={{ margin: "48px 0" }}
                  className="border-bottom"
                />
              </div>

              <div
                className="container container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "24px 0 48px 0",
                  }}
                  className=" text-center bg-body-tertiary rounded-3 role-panel-section"
                >
                  <div
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#F8F9FA",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="images/parent-icon.png"
                      style={{
                        width: "42px",
                        height: "42px",
                      }}
                    />
                  </div>
                  <h2
                    style={{
                      width: "65%",
                      marginBottom: "12px",
                      textAlign: "center",
                      marginRight: 0,
                      padding: 0,
                    }}
                    className="fw-bold text-body-emphasis feature-title"
                  >
                    Veli Paneli
                  </h2>
                  <p
                    style={{ fontWeight: "300", margin: 0, padding: 0 }}
                    className="hero-subtitle"
                  >
                    Çocuğunuzun tüm çalışmalarını tek bir yerden takip edin.
                    Çalışma aktivitelerini ve girdiği denemelerdeki başarısını
                    görün. Analiz ekranında günlük, haftalık, aylık çalışma
                    ortalamalarını ve girdiği denemelerdeki net değişimlerini inceleyin ve
                    genel gidişatı takip edin. İhtiyaç olduğunu düşündüğünüzde
                    kurum ve öğretmenler ile iletişime geçip çocuğunuz hakkında
                    konuşun.
                  </p>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ marginBottom: "48px", padding: "0 36px" }}
              >
                <div style={{ margin: 0 }} className="row align-items-center">
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/veli-13.png"
                      onClick={() => {
                        setSelectedImage("images/veli-13.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-warning-subtle border border-warning-subtle text-warning-emphasis rounded-pill"
                    >
                      Veliler İçin
                    </span>

                    <h2
                      style={{
                        maxWidth: "500px",
                        marginBottom: "12px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Çocuğunuzun <br /> Çalışmalarını Takip Edin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        textAlign: "end",
                        marginRight: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Veli panelinde
                      bulunan konu takibi, ders çalışma süresi, çözülen soru
                      sayısı ve deneme sınavları gibi ekranlarla çocuğunuzun
                      çalışmalarını takip ederken analizler ekranında
                      çocuğunuzun çalışmalarına dair analizler ve grafikler
                      görüntüleyin.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="container col-xl-12 col-lg-12 container-with-padding"
                style={{ padding: "0 36px" }}
              >
                <div
                  style={{ margin: 0 }}
                  className="row flex-lg-row-reverse align-items-center"
                >
                  <div
                    style={{ padding: "4px" }}
                    className="col-10 col-sm-8 col-lg-6 feature-container"
                  >
                    <img
                      src="images/veli-2.png"
                      onClick={() => {
                        setSelectedImage("images/veli-2.png")
                        handleShow2()
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                      width="700"
                      height="500"
                      loading="eager"
                    />
                  </div>
                  <div
                    className="col-lg-6 feature-container"
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <span
                      style={{ marginBottom: "8px" }}
                      className="badge feature-badge bg-warning-subtle border border-warning-subtle text-warning-emphasis rounded-pill"
                    >
                      Veliler İçin
                    </span>

                    <h2
                      style={{ maxWidth: "500px", marginBottom: "12px" }}
                      className="fw-bold text-body-emphasis feature-title"
                    >
                      Çocuğunuzun Çalışmalarını ve Durumunu Analiz Edin
                    </h2>
                    <p
                      style={{
                        fontWeight: "300",
                        maxWidth: "500px",
                        margin: 0,
                        padding: 0,
                      }}
                      className="feature-description-2"
                    >
                      Veli panelinde sizlere sunmuş olduğumuz tüm çalışma
                      verileri ve bunların sonucunda oluşan analizleri
                      çocuğunuzun durumunu tespit etmek için kullanın. İhtiyaç
                      halinde kurum ve öğretmenler ile iletişime geçip çocuğunuz
                      hakkında konuşun.
                    </p>
                  </div>
                </div>
              </div>

              <div className="container">
                <div style={{ margin: "48px 0" }} className="border-bottom" />
              </div>

              <div
                className="container container-with-padding"
                style={{ padding: "0 36px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "48px 0 39px 0",
                  }}
                  className="text-center bg-body-tertiary rounded-3 role-panel-section"
                >
                  <div
                    id="responsive-image"
                    style={{
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="images/mockups.png"
                      style={{
                        width: "90%",
                        maxWidth: "650px",
                        maxHeight: "600px",
                        margin: 0,
                        padding: 0,
                      }}
                      className="d-block mx-lg-auto img-fluid"
                      alt=""
                    />
                  </div>
                  <h2
                    style={{
                      marginBottom: "12px",
                      textAlign: "center",
                      marginRight: 0,
                      padding: 0,
                    }}
                    className="fw-bold text-body-emphasis feature-title-2"
                  >
                    Dilediğiniz Cihazdan <br />
                    Takip Edin
                  </h2>
                  <p
                    style={{ fontWeight: "300", margin: "0 0 12px 0", padding: 0 }}
                    className="hero-subtitle"
                  >
                    Web uygulamamız tüm cihaz ve ekranlara tam uyumludur.
                    Dilerseniz telefonunuzda ve tabletinizde web deneyiminden
                    farksız şekilde sistemimizi kullanabilirsiniz.
                  </p>
                  <p
                    style={{ fontWeight: "300", margin: 0, padding: 0 }}
                    className="hero-subtitle"
                  >
                    Dilerseniz, mobil uygulamamızı App Store ve Google Play
                    Store'dan indirebilirsiniz. Özellikle öğretmen ve
                    öğrencilerimiz için mobil uygulamamız büyük bir kullanım
                    kolaylığı sağlamaktadır.
                  </p>
                  <div
                    id="badge-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "12px",
                    }}
                  >
                    <a
                      href="https://apple.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        id="badge-1"
                        src="images/apple-store.png"
                        style={{
                          maxWidth: "142px",
                          maxHeight: "200px",
                          padding: 0,
                          marginRight: "11px",
                        }}
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://google.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        id="badge-2"
                        src="images/google-play-store.png"
                        style={{
                          maxWidth: "160px",
                          maxHeight: "200px",
                          margin: 0,
                          padding: 0,
                        }}
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div
                id="iletisim-paneli-line"
                className="container"
                style={{ marginTop: "48px", marginBottom: "24px" }}
              >
                <div
                  className="position-relative p-3 text-center text-muted bg-body border border-dashed rounded-5"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#F8F9FA",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="images/postbox-icon.png"
                      style={{
                        width: "42px",
                        height: "42px",
                      }}
                    />
                  </div>
                  <h2
                    style={{
                      marginBottom: "12px",
                      textAlign: "center",
                      marginRight: 0,
                      padding: 0,
                      marginTop: "6px",
                    }}
                    className="fw-bold text-body-emphasis feature-title-2"
                  >
                    Sizinle İletişime Geçelim
                  </h2>
                  <p
                    style={{
                      fontWeight: "300",
                      margin: 0,
                      padding: 0,
                      marginBottom: "24px",
                    }}
                    className="hero-subtitle"
                  >
                    Daha fazla bilgi almak ve kurumunuzda Derece: YKS
                    sistemimizi kullanmak isterseniz aşağıdaki satış talebi
                    formunu doldurabilirsiniz.
                  </p>

                  <Button
                    onClick={() => handleShow()}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "24px",
                    }}
                    variant="outline-primary"
                  >
                    Satış Talebi
                  </Button>
                </div>
              </div>

              <div className="container">
                <footer
                  className="d-flex flex-wrap justify-content-between align-items-center my-4 border-top"
                  style={{
                    display: "flex",
                    flexDirection: width < 1400 ? "column" : "row",
                  }}
                >
                  <p
                    className="col-md-4 text-body-secondary"
                    style={{
                      textAlign: width < 1400 && "center",
                      marginTop: width < 1400 && "24px",
                      marginBottom: width < 1400 ? 0 : 0,
                    }}
                  >
                    &copy; Derece, Inc.
                  </p>

                  <a
                    style={{ display: width < 1400 && "none" }}
                    href="/"
                    className="col-md-4 d-flex align-items-center justify-content-center mb-2 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
                  >
                    <img
                      alt=""
                      src="images/dereceyks-logo-circle.png"
                      style={{
                        display: width < 1400 && "none",
                        width: "48px",
                        height: "48px",
                        marginBottom: "12px",
                        marginTop: "24px",
                      }}
                    />
                  </a>

                  <ul
                    className="nav col-md-4"
                    style={{
                      width: width < 1400 && "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: width < 1400 ? "center" : "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <li id="hoverLinks" className="nav-item">
                      <p
                        onClick={handleShow4}
                        style={{ margin: 0, cursor: "pointer" }}
                        className="nav-link px-2 text-body-secondary"
                      >
                        İletişim
                      </p>
                    </li>
                    <li id="hoverLinks" className="nav-item">
                      <a
                        className="nav-link px-2 text-body-secondary"
                        href="https://www.instagram.com/derece.yks"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Medya
                      </a>
                    </li>
                    {/* <li id="hoverLinks" className="nav-item">
                      <a
                        href="https://twitter.com/dereceyks"
                        className="nav-link px-2 text-body-secondary"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter
                      </a>
                    </li> */}
                    <li id="hoverLinks" className="nav-item">
                      <a
                        href="/sozlesmeler"
                        className="nav-link px-2 text-body-secondary"
                      >
                        Sözleşmeler
                      </a>
                    </li>
                  </ul>
                </footer>
              </div>
            </Container>

            <Modal
              size="lg"
              centered
              show={show}
              onHide={handleClose}
              keyboard={false}
              backdrop={"static"}
            >
              <Modal.Header closeButton>
                <Modal.Title>Satış Talep Formu</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Ad Soyad *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" value={name2} onChange={(e) => setName2(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Kurum Adı *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Öğrenci Sayısı *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" value={studentCount} onChange={(e) => setStudentCount(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Öğretmen Sayısı *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" value={teacherCount} onChange={(e) => setTeacherCount(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Mail Adresi *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" value={mail2} onChange={(e) => setMail2(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Telefon Numarası *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" maxLength={10} value={phone2} onChange={(e) => setPhone2(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicEmail">
                        <FloatingLabel controlId="floatingInput" label="Adres *">
                          <Form.Control type="text" placeholder="Input" value={adress} onChange={(e) => setAdress(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col style={{ display: "flex", alignItems: "flex-end" }}>
                      <Button
                        style={{ width: "100%" }}
                        variant="outline-primary"
                        onClick={() => sendMail("sales")}
                      >
                        Gönder
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal
              fullscreen
              centered
              show={show2}
              onHide={handleClose2}
              keyboard={false}
              onExit={() => {
                setSelectedImage()
              }}
              backdrop="static"
            >
              <Modal.Header closeButton>
                {/* <Modal.Title>Fotoğraf</Modal.Title> */}
              </Modal.Header>
              <Modal.Body>
                {
                  selectedImage && <img
                    src={selectedImage}
                    className="d-block mx-lg-auto img-fluid"
                    alt=""
                    style={{ height: "100%", objectFit: "contain" }}
                    loading="eager"
                  />
                }
              </Modal.Body>
            </Modal>

            <Modal
              fullscreen
              centered
              show={show3}
              onHide={handleClose3}
              keyboard={false}
              backdrop="static"
            >
              <Modal.Header closeButton>
                {/* <Modal.Title>Fotoğraf</Modal.Title> */}
              </Modal.Header>
              <Modal.Body>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <ReactPlayer width={"100%"} height={"100%"} controls={true} url='https://youtu.be/niNwqJZxHnM' />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              size="lg"
              centered
              show={show4}
              onHide={handleClose4}
              keyboard={false}
              backdrop={"static"}
            >
              <Modal.Header closeButton>
                <Modal.Title>İletişim Formu</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Ad Soyad *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" value={name} onChange={(e) => setName(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Mail Adresi *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Input" value={mail} onChange={(e) => setMail(e.target.value.trim())} />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>


                  </Row>

                  <Row>
                    <Col>

                      <Form.Group controlId="formBasicEmail">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Mesajınız *"

                        >
                          <Form.Control style={{ height: "100%" }} as="textarea" rows={4} type="text" placeholder="Input" value={message} onChange={(e) => setMessage(e.target.value)} />
                        </FloatingLabel>
                      </Form.Group>

                    </Col>
                    <Col>
                      <Row>
                        <Form.Group controlId="formBasicEmail">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Telefon Numarası *"
                            className="mb-3"
                          >
                            <Form.Control maxLength={10} type="text" placeholder="Input" value={phone} onChange={(e) => setPhone(e.target.value.trim())} />
                          </FloatingLabel>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Container>
                          <Button
                            style={{ width: "100%" }}
                            variant="outline-primary"
                            onClick={() => sendMail("contact")}
                          >
                            Gönder
                          </Button>
                        </Container>
                      </Row>
                    </Col>

                  </Row>


                </Form>
              </Modal.Body>
            </Modal>
          </Container>
        </>
      )}
    </>
  );
}
